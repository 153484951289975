import React from "react"
import { motion } from "framer-motion"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import DesktopHeader from "../components/DesktopHeader/DesktopHeader"
import MobileProductHeader from "../components/MobileProductHeader/MobileProductHeader"

import { useWindowSize } from "../utils/useWindowSize"

const ThankYouPage = () => {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="Privacy" />
      {width > 1278 ? (
        <DesktopHeader />
      ) : (
        <MobileProductHeader productName={""} />
      )}
      <motion.div id={"privacy-container"}>
        <h1 className={"header"}>TERMS AND CONDITIONS</h1>
        <h3 className={"privacy-header"}>
          This Privacy Policy describes how your personal information is
          collected, used, and shared when you visit or make a purchase from
          immaterialcorp.com.
        </h3>
        <h3 className={"privacy-header"}>
          WHAT PERSONAL INFORMATION WE COLLECT
        </h3>
        <p>
          If you agree to our cookie policy, we collect certain information
          about your device, including information about your web browser, IP
          address, time zone, and some of the cookies that are installed on your
          device.
        </p>
        <p>
          Additionally, as you browse the Site, we collect information about the
          individual web pages or products that you view, what websites or
          search terms referred you to the Site, and information about how you
          interact with the Site. We refer to this automatically collected
          information as Device Information.
        </p>
        <p>We collect Device Information using the following technologies:</p>
        <p>
          - Cookies are data files that are placed on your device or computer
          and often include an anonymous unique identifier.
        </p>
        <p>
          - Log files track actions occurring on the Site, and collect data
          including your IP address, browser type, Internet service provider,
          referring/exit pages, and date/time stamps.
        </p>
        <p>
          Also, when you make a purchase or attempt to make a purchase through
          the Site, we collect certain information from you, including your
          name, billing address, shipping address, payment information, email
          address, and phone number. This is called Order Information.
        </p>
        <p>
          By Personal Information in this Privacy Policy, we are talking both
          about Device Information and Order Information.
        </p>
        <h3 className={"privacy-header uppercase"}>
          HOW WE USE YOUR PERSONAL INFORMATION
        </h3>
        <p>
          We use the Order Information that we collect generally to fulfill any
          orders placed through the Site (including processing your payment
          information, arranging for shipping, and providing you with invoices
          and/or order confirmations).
        </p>
        <p>Additionally, we use this Order Information to:</p>

        <p>- Communicate with you. </p>
        <p>- Screen our orders for potential risk or fraud. </p>
        <p>
          - When in line with the preferences you have shared with us, provide
          you with information or advertising relating to our products or
          services.
        </p>
        <p>
          We use the Device Information that we collect to help us screen for
          potential risk and fraud (in particular, your IP address), and more
          generally to improve and optimize our Site.
        </p>
        <h3 className={"privacy-header uppercase"}>
          SHARING YOUR PERSONAL INFORMATION
        </h3>
        <p>
          We use Google Analytics to help us understand how our customers use
          immaterialcorp.com.{" "}
          <a href="https://policies.google.com/privacy?hl=en">
            How Google uses your Personal Information
          </a>
          .
        </p>
        <p>
          Finally, we may also share your Personal Information to comply with
          applicable laws and regulations, to respond to a subpoena, search
          warrant or other lawful requests for information we receive, or to
          otherwise protect our rights.
        </p>
        <h3 className={"privacy-header uppercase"}>YOUR RIGHTS</h3>
        <p>
          If you are a European resident, you have the right to access the
          personal information we hold about you and to ask that your personal
          information is corrected, updated, or deleted. If you would like to
          exercise this right, please contact us.
        </p>
        <p>
          Additionally, if you are a European resident we note that we are
          processing your information in order to fulfill contracts we might
          have with you (for example if you make an order through the Site), or
          otherwise to pursue our legitimate business interests listed above.
        </p>
        <h3 className={"privacy-header uppercase"}>DATA RETENTION</h3>
        <p>
          When you place an order through the Site, we will maintain your Order
          Information for our records unless and until you ask us to delete this
          information.
        </p>
        <h3 className={"privacy-header uppercase"}>MINORS</h3>
        <p>The Site is not intended for individuals under the age of 12.</p>
        <h3 className={"privacy-header uppercase"}>CHANGES</h3>
        <p>
          We may update this privacy policy from time to time in order to
          reflect, for example, changes to our practices or for other
          operational, legal or regulatory reasons.
        </p>
        <p>
          If you have questions and/or require more information, do not hesitate
          to contact us at{" "}
          <a href="mailto:immaterialcorp@gmail.com">immaterialcorp@gmail.com</a>
        </p>
      </motion.div>
    </Layout>
  )
}

export default ThankYouPage
